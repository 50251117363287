import './App.css';
import Wrapper from "./components/Wrapper";
import Screen from "./components/Screen";
import ButtonBox from "./components/ButtonBox";
import Button from "./components/Button";
import { useState } from "react";
function App() { 
  var [stack, setStack] = useState([]);
  var [screen, setScreen] = useState('');
  var [prevAnswer, setPrevAnswer] = useState(0);
  var btns =   ["C", "±", "%", "÷", 7, 8, 9, "×", 4, 5, 6, "-", 1, 2, 3, "+", 0, ".", "="];
  const btnClicked= (key) =>{
    updateScreen([...stack, key]);
    if(key=== 'C') {
      setStack([])
      setPrevAnswer(0);
    }
    else if (key === '=') {
      operation()
      console.log(prevAnswer)
      setScreen(prevAnswer);
    }
    else{
      setStack(stack => [...stack, key]);
    }
  }

  const updateScreen = (stackScreen) =>{
    var screenNumbers = [];
    let temp = '';
    for (let char of stackScreen){
      if (char < 10 || char === '.'){
        temp += char;
      } 
      else {
        temp = '';
      };
    }
    screenNumbers = temp;
    setScreen(screenNumbers);
  }

  const operation = () => {
    var numbers = [];
    var functions = [];
    let temp = '';
    for (let char of stack){
      if (char < 10 || char === '.'){
        temp += char;
      } 
      else {
        numbers.push(temp);
        functions.push(char);
        temp = '';
      };
    }
    // setStack([]);
    numbers.push(temp);
    console.log(numbers, functions);
    console.log(functions[functions.length-1]);
    console.log(prevAnswer)
    if (prevAnswer == 0){
      setPrevAnswer(Number(numbers[0]));
    }
    else if (functions[functions.length-1] == '+'){
      setPrevAnswer((Number(prevAnswer) + Number(numbers[numbers.length - 1])).toString());
    }
    else if (functions[functions.length-1] == '-'){
      setPrevAnswer((Number(prevAnswer) - Number(numbers[numbers.length - 1])).toString());
    }
    else if (functions[functions.length-1] == '×'){
      setPrevAnswer((Number(prevAnswer) * Number(numbers[numbers.length - 1])).toString());
    }
    else if (functions[functions.length-1] == '÷'){
      setPrevAnswer((Number(prevAnswer) / Number(numbers[numbers.length - 1])).toString());
    }

  }




  return <Wrapper>
          <Screen value={screen.length >= 1 ? screen: "0"}/> 
          <ButtonBox>
            {btns.map((key, i) => {
                  return <Button 
                    className={"btn " + `${key === "=" ? "equal" : key < 10 || key === '.' ? 'number-key' : key === 'C' ? 'cancel-key' : 'functions-key'}`} 
                    value={key} 
                    onClick={() => {btnClicked(`${key}`); }} 
                  />
            })}
          </ButtonBox>
        </Wrapper>     
}
export default App;
